import AudioRecorder from 'audio-recorder-polyfill'
console.log(AudioRecorder);
window.MediaRecorder = AudioRecorder;
console.log(MediaRecorder.notSupported);
// if (MediaRecorder.notSupported) {

// } else {
//     console.log('Working If statement audio...');
//     window.MediaRecorder = AudioRecorder
// }

var config = {
    apiKey: "AIzaSyDEMBxYRWhPBwI_0SSLw7fhujLZSXgwrCQ",
    authDomain: "under-kitchen.firebaseapp.com",
    projectId: "under-kitchen",
    storageBucket: "under-kitchen.appspot.com",
    messagingSenderId: "1037917768456",
    appId: "1:1037917768456:web:a21a2eb754a452ec657abd",
    measurementId: "G-3FQ7G9SLW4"
};

let messaging = '';
if(firebase.messaging.isSupported()) {
    firebase.initializeApp(config);
    messaging = firebase.messaging();
}

function hh() {
    axios.get("/happy-hours")
    .then(res => {
        if (res.data.status) {
            window.location.reload();
            // $(".refresh-contaner").parent().load(location.href + " .refresh-contaner");
            // $("#cart_count").parent().load(location.href + " #cart_count");
        }
    })
    .catch(err => {
        window.location.reload();
        console.log(err);
    });
}

function offer() {
    axios.get("/customer-offer")
    .then(res => {
        if (res.data.status) {
            //$(".refresh-contaner").parent().load(location.href + " .refresh-contaner");
            window.location.reload();
        }
    })
    .catch(err => {
        console.log(err);
        window.location.reload();
    });
}

function addListeners() {
    var stars = document.querySelectorAll('.star');
    //console.log(stars);
    // foreach(let star in stars) {
       
    // }
    [].forEach.call(stars, function (star, index) {
        star.addEventListener('click', (function (idx) {
            console.log('adding rating on', index);
            document.querySelector('.stars').setAttribute('data-rating', idx + 1);
            console.log('Rating is now', idx + 1);
            setRating();
        }).bind(window, index));
    });

}

function setRating() {
    var stars = document.querySelectorAll('.star');
    var rating = parseInt(document.querySelector('.stars').getAttribute('data-rating'));
    [].forEach.call(stars, function (star, index) {
        if (rating > index) {
            star.classList.add('rated');
            // console.log('added rated on', index);
        } else {
            star.classList.remove('rated');
            // console.log('removed rated on', index);
        }
    });
}

function notification(payload) {
    console.log(payload)
    Swal.fire({
        position: "top-end",
        icon: "success",
        title: payload.notification.title,
        text: payload.notification.body,
        showConfirmButton: false,
        timer: 10000
    });
    $(".card-body").load(location.href + " .card-body");
    let notiCount = $(".notification-count").html();
    $(".notification-count").html(notiCount);
}

if(messaging != '') {

    messaging.onMessage(function (payload) {
        console.log("notification alert");
        notification(payload);
    });

    const updateToken = () => {
        console.log("res");
        messaging.requestPermission().then(function () {
            return messaging.getToken({ vapidKey: "BD5SJcThn6O5mtFzW98mlZ1_PTiHf_G1zIpghfo7bBg506P0UBsaNI3w4T3uAEpO43NFrnQE4KFPrBCoXTJJmaI" })
        }).then(function (token) {
            var data = new FormData();
            data.append("token", token);
            data.append("device_type", 'Browser');
            axios.post("/token-update", data)
                .then(res => {
                    console.log(res);
                    const error = document.querySelector('.notification');
                    error.classList.remove('d-flex');
                    error.classList.add('d-none');
                })
                .catch(err => {
                    if (typeof err.response.data.msg != 'undefined') {
                        $(".alert-msg").html(err.response.data.msg);
                    }
                    else {
                        let errors = "<ul>";
                        $.each(err.response.data.errors, function (key, value) {
                            errors += "<li>" + value + "</li>";
                        });
                        errors += "</ul>";
                        $(".alert-msg").html(errors);
                    }
                });
        }).catch(err => {
            $(".alert-msg").html('The notification permission was not granted and blocked instead');
            console.log(`Token Error :: ${err}`);
        });
    }
}



if ($('.noti-enb').length) {
   
    (async () => {

        // show an error message
        const showError = () => {
            const error = document.querySelector('.notification');
            error.classList.remove('d-none');
            error.classList.add('d-flex');
        }

        // check notification permission
        let granted = false;
        console.log(granted);
        if (Notification.permission === 'granted') {
            console.log("granted");
            granted = true;
        } else if (Notification.permission !== 'denied') {
            console.log("denied");
            let permission = await Notification.requestPermission();
            granted = permission === 'granted' ? true : false;
        }

        // show notification or error
        granted ? null : showError();
        // console.log("res");
    })();

    $(document).on('click', '.noti-enb', function () {
        if(Notification.permission == "granted"){
            updateToken();
        }
        else{
            (async () => {

                // show an error message
                const showError = () => {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        text: "Your Browser's notifications are blocked. Please enable it from your browser's setting to proceed further",
                        showConfirmButton: false,
                    });
                   // alert("Browser's notification is blocked")
                    const error = document.querySelector('.notification');
                    error.classList.remove('d-flex');
                    error.classList.add('d-none');
                    
                }
        
                // check notification permission
                let granted = false;
        
                if (Notification.permission === 'granted') {
                    granted = true;
                    console.log("granted");
                } else if (Notification.permission !== 'denied') {
                    let permission = await Notification.requestPermission();
                    granted = permission === 'granted' ? true : false;
                    //console.log("");
                    location.reload();
                }
        
                // show notification or error
                granted ? null : showError();
        
            })();
            //console.log("xxxxx");
        }
    });
}

// $(document).on('click', '.lrf', function () {
//     $(".sublogin").toggleClass('addClass');
// });

$(".lrf").click(function() {
    $(".sublogin").toggleClass('addClass');
    $(this).toggleClass('Aclass');
});

$(document).on('click', '.notification .close a', function () {
    console.log("bbbbb");
    $(".notification").removeClass('d-flex');
    $(".notification").addClass('d-none');
});

$(document).on('click', '.pass-show-hide', function () {
    var passInput = $(this).next('input').attr('id');
    // var passInput = $("#password");
    // var passConf = $("#password_confirmation");
    if ($("#"+passInput).attr('type') === 'password') {
        $(this).html('<i class="fa fa-eye"></i>');
        $("#"+passInput).attr('type', 'text');
        //passConf.attr('type', 'text');
    } else {
        $(this).html('<i class="fa fa-eye-slash"></i>');
        $("#"+passInput).attr('type', 'password');
        //passConf.attr('type', 'password');
    }
});

let current = location.pathname;
let ul = current.split("/");

// console.log(current);

if (ul[1] == 'destination' || ul[1] == 'food' || ul[1] == 'checkout' || ul[1] == 'cart') {
    $(document).ready(function(){
        $('[data-toggle="tooltipp"]').tooltip();  
    });

    setInterval(function () {
        let url = $('#url').val() + '/HappyHours.json';
        hh();
    }, 10000);

    $(function(){
        $("audio").on("play", function() {
            $("audio").not(this).each(function(index, audio) {
                audio.pause();
            });
        });
    });
    
    /**
     * HappyHours.json not being used anymore
     */
    // setInterval(function () {
    //     let url = $('#url').val() + '/HappyHours.json';

    //     $.ajax({
    //         url: url,
    //         type: 'HEAD',
    //         success: function () {
    //             hh();
    //         }
    //     });
    // }, 60000);
}
// $(document).on('click', '.checkdestination', function (e) {
//         e.preventDefault();
//         axios.get("/check-destination/" + $(this).attr("id"))
//             .then(res => {
//                 //console.log(res);
//                 if(res.data.msg == "Origins Available"){
//                     $(this).click();
//                     console.log("hi");
//                 }
//                 else{
//                     console.log("hi");
//                     $(this).attr("data-toggle", 'tooltip');
//                     $(this).attr("title", res.data.msg);
//                 }
//             })
//             .catch(err => {
//                 //console.log(err);
                
//             });
//     });
// if(ul[1] == 'godestination') {
//     // $(document).ready(function(){
//     //     $('[data-toggle="tooltip"]').tooltip();  
//     //     });
//         setInterval(function () {
//             $(document).on('click', '.checkdestination', function (e) {
//                 e.preventDefault();
//                 axios.get("/check-destination/" + $(this).attr("id"))
//                     .then(res => {
//                         //console.log(res);
//                        $(this).attr("data-toggle", 'tooltip');
//                        $(this).attr("title", res.data.msg);
//                     })
//                     .catch(err => {
//                         console.log(err);
//                     });
//             })
//         }, 500);
// }

if (ul[1] == 'page' || ul[1] == 'cart' || ul[1] == 'combopackage') {

    var item_count = $(".owl-carousel").find(".item").length;
    console.log(item_count);
    var navstat = '';
    if(item_count>3){
        navstat = true;
    }
    else{
        navstat = false;
    }

    $('.owl-carousel').owlCarousel({
        navigation : navstat,
        nav: false,
        dots: false,
        center: false,
        loop: true,
        margin: 15,
        autoplay: 900,
        autoPlaySpeed: 900,
        autoPlayTimeout: 900,
        autoplayHoverPause: false,
        mouseDrag: navstat,
        items: 3,
        singleItem: true,
        stagePadding: 0,
        responsive : {
            // breakpoint from 0 up
            1280 : {items:3},
            0 : {items:3},                
            991 : {items:2},
            767 : {items:2},
            576 : {items:2},
            480 : {items:1},
            360 : {items:1},
            320 : {items:1}
        }
    });
}

if (ul[1] == 'food' || ul[1] == 'page' || ul[1] == 'cart' || ul[1] == 'combopackage') {
    // var item_count = $(".owl-carousel").find(".item").length;
    // console.log(item_count);
    // var navstat = '';
    // if(item_count>3){
    //      navstat = true;
    // }
    // else{
    //      navstat = false;
    // }
    
    $(document).on('click', '.add', function () {
        
        var $input = $(this).parent().find('input');
        var $rooms = $("#quantity");
        var max = $rooms.attr('max');
        var min = $rooms.attr('min');
        var status = $rooms.attr('status');
        var foodtype = $rooms.attr('foodtype'); 
        var current_price = $(this).val();
        var a = $rooms.val();
        if(foodtype == "global"){
            var aa = (($rooms.val())/10);
            if (status == 'Happy Hour') {
                a = parseInt(a) + 20;
                aa = parseInt(aa) + 2;
                $('#food_price').html(parseInt(current_price * (parseInt(aa) / 2)).toFixed(0));
            } else {
                a=parseInt(a)+10;
                aa=parseInt(aa)+1;
                $('#food_price').html(parseInt(current_price * parseInt(aa)).toFixed(0));
            }
            
        }else if(foodtype == "normal"){
            var aa = (($rooms.val())/5);
            if (status == 'Happy Hour') {
                a = parseInt(a) + 10;
                aa = parseInt(aa) + 2;
                $('#food_price').html(parseInt(current_price * (parseInt(aa) / 2)).toFixed(0));
            } else {
                a=parseInt(a)+5;
                aa=parseInt(aa)+1;
                $('#food_price').html(parseInt(current_price * parseInt(aa)).toFixed(0));
            }
            
        }
        
        axios.post("/save_quantity", {
            qty: a,
            price: $('#food_price').html(),
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            });
        if (a == max) {
            $(".add").prop("disabled", true);
        }

        if (a > min) {
            $(".sub").prop("disabled", false);
        }

        a = a > 9 ? "" + a : "0" + a;
        $rooms.val(a);
    });

    $(document).on('click', '.sub', function () {
        var $rooms = $("#quantity");
        var max = $rooms.attr('max');
        var min = $rooms.attr('min');
        var status = $rooms.attr('status'); 
        var foodtype = $rooms.attr('foodtype'); 
        //console.log(foodtype);
        var current_price = $(this).val();
        var a = $rooms.val();
        if(foodtype == "global"){
            var aa = (($rooms.val())/10);
            if (status == 'Happy Hour') {
                a = parseInt(a) - 20;
                aa = parseInt(aa) - 2;
                $('#food_price').html(parseInt(current_price * (aa / 2)).toFixed(0));
            } else {
                a=parseInt(a)-10;
                aa=parseInt(aa)-1;;
                $('#food_price').html(parseInt(current_price * aa).toFixed(0));
            }
        }else{
            var aa = (($rooms.val())/5);
            if (status == 'Happy Hour') {
                a = parseInt(a) - 10;
                aa = parseInt(aa) - 2;
                $('#food_price').html(parseInt(current_price * (aa / 2)).toFixed(0));
            } else {
                a=parseInt(a)-5;;
                aa=parseInt(aa)-1;;
                $('#food_price').html(parseInt(current_price * aa).toFixed(0));
            }
        }
       

        if (a == min) {
            $(".sub").prop("disabled", true);
        }
        if (a < max) {
            $(".add").prop("disabled", false);
        }
        a = ("0" + a).slice(-2);
        // $(".sub").prop("disabled", !a);
        $rooms.val(a);
    });
}

$(document).on('click', '.add_cart', function(){
    var qty = $("#quantity").val();
    var trip_id = $("#trip_id").val();
    /**
     * @important using this to not affect existing combo_id flow. need to check API
     */
    var combo_alt_id = $("#food_id").val(); //basically combo id
    
    if(trip_id == ''){
        var combo_id = $("#combo_id").val();
    } else{
        var combo_id = '';
    }
    //console.log(trip_id);
    var food_price = $("#food_price").html();
    axios.post("/cart", {
        qty: qty,
        trip_id: trip_id,
        combo_id: combo_id,
        combo_alt_id: combo_alt_id, //to maintain old flow
    })
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
        setTimeout(function () {
            window.location.reload();
        }, 500);
    })
    .catch(err => {
        //console.log(err.response.data);
        if(err.response.data.data == 1){
            Swal.fire({
                title: err.response.data.msg,
                text: "Do you want to add more?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'No',
                confirmButtonText: 'Yes!'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.post("/updatecart/" + err.response.data.cart_id, {
                        qty: qty,
                        trip_id: trip_id,
                        combo_id: combo_alt_id,
                    })
                    .then(res => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        setTimeout(function () {
                            window.location.reload();
                        }, 500);
                    })
                    .catch(err => {
                        Swal.fire(err.response.data.msg, "", "error");
                        console.log(err);
                    });
                }
            })
        }
        else if(err.response.data.data == 2){
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: err.response.data.msg,
                showConfirmButton: false,
            });
            setTimeout(function () {
                window.location.reload();
            }, 500);
        }
        else{
            Swal.fire({
                title: err.response.data.msg,
                text: "Your cart contains items from other Destination. Would you like to reset your cart for adding items from this Destination?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it !'
                }).then((result) => {
                if (result.isConfirmed) {
                    axios.get("/emptyCart")
                    .then(res => {
                        axios.post("/cart", {
                            qty: qty,
                            trip_id: trip_id,
                        })
                        .then(res => {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: res.data.msg,
                                showConfirmButton: false,
                                timer: 1500
                            });
                            setTimeout(function () {
                                // $('#modal-sm').modal("hide");
                                window.location.reload();
                            }, 500);
                            // location.reload();
                            //window.location = '/cart';
                            
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    })
                    .catch(err => {
                        Swal.fire(err.response.data.msg, "", "error");
                        console.log(err);
                    });
                }
            })
        }
    });
})

$(document).on('click', '.del_cart', function(){
    var cart_id = $(this).val();
    console.log(cart_id);
    axios.delete('del_cart/' + cart_id)
    .then(res => {
        console.log(res.data);
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
        if(res.data.data.length > 0) {
            // $(".items").parent().load(location.href + " .items");
            // $("#cart_count").parent().load(location.href + " #cart_count");
            // $(".patment-details").parent().load(location.href + " .patment-details");
            setTimeout(function () {
                // window.location = '/cart';
                location.reload();
            }, 1450);
        }
        else{
            window.location = '/home';
        }
        // if ($("#cart_count").length > 0) {
                
        // }
    })
    .catch(err => {
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: res.data.err,
            showConfirmButton: false,
        });
    });
})

$(document).on('click', '.add_offer_cart', function(e){
    e.preventDefault();
    // console.log('sagnik offer');
    var data = $('.add_offer_cart').data('details');

    console.log(e.target);
    let loopId = '#' + $(e.target).data("loop-id");
    console.log(loopId);
    var trip_id = $(loopId + " .trip_id").val();
    var food_id = $(loopId + " .food_id").val(); //@sagnik
    var destination_id = $(loopId + " .destination_id").val(); //@sagnik
    var qty = $(loopId + " .quantity").val();

    console.log(trip_id);

    var food_price = $("#food_price").html();
    axios.post("/offer-checkout", {
        trip_id: trip_id,
        qty: qty,
        food_id : food_id,
        destination_id : destination_id,
        make_offer : 'true'
    })
    .then(res => {
        if(res.data.data == 1){
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(function () {
                window.location.reload();
            }, 1550);
        }
        else{
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(function () {
                window.location.reload();
            }, 1550);
        }
        
    })
    .catch(err => {
        console.log(err);
        Swal.fire({
            title: err.response.data.msg,
            text: "Your cart contains items from other Destination. Would you like to reset your cart for adding items from this Destination?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it !'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.get("/emptyCart")
                .then(res => {
                    axios.post("/offer-checkout", {
                        trip_id: trip_id
                    })
                    .then(res => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        // setTimeout(function () {
                        //     window.location = '/cart';
                        // }, 500);
                        
                    })
                    .catch(err => {
                        console.log(err);
                    });
                })
                .catch(err => {
                    Swal.fire(err.response.data.msg, "", "error");
                    console.log(err);
                });
            }
        })
    });
})

// REVIEW SECTION -------------------------------------------------
if (ul[1] == 'review') {
    
    $(document).ready(function() {
        $('.delete_btn').hide();
        $('.submit-review').hide();
    });

    var audio = new FormData();
    var micPermission = '';
    // console.log("review section loaded")
    // Check microphone permission given or not on load review page | Added by Subhodeep Bhattacharjee 
    if(navigator.permissions) {
        console.log("permissions")
        navigator.permissions.query({ name: 'microphone' }).then(function(result) {
            micPermission = result.state;
            console.log(micPermission);
            if(result.state == 'prompt') {
                const error = document.querySelector('.micro-alt');
                error.classList.remove('d-none');
                error.classList.add('d-flex');
            } else if(result.state == 'denied') {
                const error = document.querySelector('.micro-alt');
                error.classList.remove('d-none');
                error.classList.add('d-flex');
            } else {
                console.log('enabled');
                navigator.mediaDevices.getUserMedia({audio: true}).then( stream => {
                    handlerFunction(stream);
                    console.log(stream);
                    $('.play-review').children().attr("id", "record");
                    //$('.play-review').children().html('Record your Voice');
                    $('.play-review').children().html('<div class="icon"><img src="/images/voice-record.svg" alt="" class="play_icon"><img src="/images/stop-icon.png" alt="" class="stop_icon"></div>');
                }).catch( err => {
                    console.log("You got an error: " + err)
                });
            }
        });
    } else {
        console.log("Navigator Permission not available (in safari) ")
    }

    $(document).on('click', '.microphone-enb', function () {
        console.log('enabled microfone')
        const getLocalStream = () => {
            
            const error = document.querySelector('.micro-alt');
            error.classList.remove('d-flex');
            error.classList.add('d-none');

            navigator.mediaDevices.getUserMedia({audio: true}).then( stream => {
                handlerFunction(stream);
                console.log(stream);
                $('.play-review').children().attr("id", "record");
                //$('.play-review').children().html('Record your Voice');
                $('.play-review').children().html('<div class="icon"><img src="/images/voice-record.svg" alt="" class="play_icon"><img src="/images/stop-icon.png" alt="" class="stop_icon"></div>');

                location.reload();
            }).catch( err => {
                console.log("u got an error: " + err)
            });
        }

        const showError = () => {
            Swal.fire({
                position: "top-end",
                icon: "error",
                text: "Your Browser's microphone are blocked. Please enable it from your browser's setting to proceed further",
                showConfirmButton: false,
            });
            
            const error = document.querySelector('.micro-alt');
            error.classList.remove('d-flex');
            error.classList.add('d-none');
        }

        if(micPermission == 'denied') {
            showError();
        } else {
            getLocalStream();
        }
    });

    // Check which browser is present now.
    const detectBrowser = () => { 
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            return 'Opera';
        } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
            return 'Chrome';
        } else if(navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
            return 'Firefox';
        } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
            return 'IE';//crap
        } else {
            return 'Unknown';
        }
    }

    // If browser is firefox then run this codeblock only.
    
    if(navigator.mediaDevices) {
        // console.log('media record');
        navigator.mediaDevices.getUserMedia({audio: true}).then( stream => {
            handlerFunction(stream);
            $('.play-review').children().attr("id", "record");
            $('.play-review').children().html('<div class="icon"><img src="/images/voice-record.svg" alt="" class="play_icon"><img src="/images/stop-icon.png" alt="" class="stop_icon"></div>');
        }).catch( err => {
            localStorage.setItem("micPermission", "denied");
            const error = document.querySelector('.micro-alt');
            error.classList.remove('d-none');
            error.classList.add('d-flex');
            console.log("mic permission error");
        });

        $(document).on('click', '.microphone-enb', function () {
            console.log('microphone-enb');
            const getLocalStream = () => {    
                const error = document.querySelector('.micro-alt');
                error.classList.remove('d-flex');
                error.classList.add('d-none');
    
                navigator.mediaDevices.getUserMedia({audio: true}).then( stream => {
                    handlerFunction(stream);
                    console.log(stream);
                    $('.play-review').children().attr("id", "record");
                    $('.play-review').children().html('<div class="icon"><img src="/images/voice-record.svg" alt="" class="play_icon"><img src="/images/stop-icon.png" alt="" class="stop_icon"></div>');    
                    
                    location.reload();
                }).catch( err => {
                    console.log("u got an error: " + err)
                });
            }
    
            const showError = () => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    text: "Your Browser's microphone are blocked. Please enable it from your browser's setting to proceed further",
                    showConfirmButton: false,
                });
    
                const error = document.querySelector('.micro-alt');
                error.classList.remove('d-flex');
                error.classList.add('d-none');
            }
            
            if(localStorage.getItem("micPermission") == 'denied') {
                showError();
            } else {
                getLocalStream();
            }
        }); 
    } else {
        console.error("Permission not supported for mediaDevices [eg safari]");
    }
    // Microphone permission checking end... 

    var rec=null;
    var audioChunks = [];
    
    function handlerFunction(stream) {
        console.log('handle func')
        rec = new MediaRecorder(stream);
        // console.log(rec);
        rec.addEventListener('dataavailable', e => {
            audioChunks.push(e.data);
            console.log(rec.state);

            if (rec.state == "inactive") {
               
                /**
                 * @todo crhome does not respect codec and type, handle accordingly in server validation
                 *  crome type is always video/webm refered bug https://bugs.chromium.org/p/chromium/issues/detail?id=859199
                 */
                // let blob = new Blob(audioChunks, { 'type' : 'audio/ogg; codecs=opus' }); //old
                // let blob = new Blob(audioChunks,  { 'type' : 'audio/ogg; codecs=opus' });
                let blob = new Blob(audioChunks,  { 'type' : 'audio/mpeg; codecs=opus' });
                recordedAudio.src = URL.createObjectURL(blob);

                //Debug audio
                console.log(blob)
                console.log(recordedAudio.src)

                recordedAudio.controls = true;
                recordedAudio.autoplay = true;
                // sendData(blob)
                audio.append('audio', blob);
            }
          })

        rec.ondataavailable = e => {
            audioChunks.push(e.data);
            console.log(rec.state);

            if (rec.state == "inactive") {
               
                /**
                 * @todo crhome does not respect codec and type, handle accordingly in server validation
                 *  crome type is always video/webm refered bug https://bugs.chromium.org/p/chromium/issues/detail?id=859199
                 */
                // let blob = new Blob(audioChunks, { 'type' : 'audio/ogg; codecs=opus' }); //old
                let blob = new Blob(audioChunks,  { 'type' : 'audio/ogg; codecs=opus' });
                // let blob = new Blob(audioChunks,  { 'type' : 'audio/mpeg; codecs=opus' });
                recordedAudio.src = URL.createObjectURL(blob);

                //Debug audio
                console.log(blob)
                console.log(recordedAudio.src)

                recordedAudio.controls = true;
                recordedAudio.autoplay = true;
                // sendData(blob)
                audio.append('audio', blob);
            }
        }
    }

    // function sendData(data) { }

    $(document).on('click', '#record', function () {
        console.log("Record");
        // console.log(rec);
        $('#record').html('<div class="icon"><img src="/images/voice-record.svg" alt="" class="play_icon"><img src="/images/stop-icon.png" alt="" class="stop_icon"></div>');
        $('#record').attr("id", "stopRecord");
        rec.start();
    });

    $(document).on('click', '#stopRecord', function () {
        $('.delete_btn').show();
        $('#stopRecord').hide();
        $('.submit-review').show();

        //$('#stopRecord').html('Delete Recording');
        // $('#stopRecord').attr("id", "deleteRecord");
        $('.delete_btn').attr("id", "deleteRecord");
        $('.voice-record').html('');
        rec.stop();
    });

    $(document).on('click', '#deleteRecord', function () {
        //$('#deleteRecord').html('Record your Voice');
        //$('#deleteRecord').html('<a href="javascript:void(0)" class="icon"><img src="/images/voice-record.svg" alt=""></a>');
        $('.submit-review').hide();
        $('.delete_btn').hide();
        $('#stopRecord').show();
        $('#stopRecord').html('<div class="icon"><img src="/images/voice-record.svg" alt="" class="play_icon"><img src="/images/stop-icon.png" alt="" class="stop_icon"></div>');
        $('#stopRecord').attr("id", "record");
        $('.voice-record').html('');

        recordedAudio.src = '';
        recordedAudio.controls = false;
        recordedAudio.autoplay = false;
        location.reload();
    });

    //initial setup
    // document.addEventListener('DOMContentLoaded', function () {
    //     addListeners();
    //     setRating(); //based on value inside the div
    // });
    $(document).ready(function() {
        addListeners();
        setRating(); //based on value inside the div
    });

    $(document).on('click', '.submit-review', function () {
        $('.delete_btn').hide();
        let id = $(this).val().split('~');

        let rating = parseInt(document.querySelector('.stars').getAttribute('data-rating'));
        audio.append('order_id', id[0]);
        audio.append('food_id', id[1]);
        audio.append('rating', rating);
        axios.post('/review', audio,  { "headers" : { "Content-Type": "multipart/form-data" }},
        )
        .then(res => {
            recordedAudio.src = '';
            recordedAudio.controls = false;
            recordedAudio.autoplay = false;
            // $("#food-table").parent().load(location.href + " #food-table");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(function () {
                window.location = '/my-orders';
            }, 2000);
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                if(value == 'The rating must be greater than 0.') {
                    errors += "<li>" + 'Please select any rating' + "</li>";
                } else {
                    errors += "<li>" + value + "</li>";
                }
            });
            errors += "</ul>";
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: errors,
            });
            $('#deleteRecord').css('display','block');
        });
    });
}

// Working...
if (current == '/offers' || current == '/my-offers' || current == '/cart') {
    setInterval(function () {
        let url = $('#url').val() + '/MakeOffers.json';
        $.ajax({
            url: url,
            type: 'HEAD',
            success: function () {
                // file exists
                offer();
            },
            error: function (status) {
                console.log("status: " + status);
                window.location.reload();
            }
        });
    }, 60000);
}
// FRONT END ----------------------------------------------------------------
$(document).on('click', '.play-audio', function (e) {
    // console.log(e.target)
    // console.log(e.target.id) // this div
    
    let id = $(this).val();
    var el = document.getElementById(id);

    $('.play-audio').each(function() {
        let auid = $(this).val();
        let audioo = document.getElementById("audio" + auid);

        document.getElementById("audio" + auid).pause();   // Pause all audio
        document.getElementById("audio" + auid).currentTime = 0;  // Set audio time 0
        document.getElementById(auid).innerHTML = '<i class="fa fa-play"></i> Play';  // Inner HTML change to play
        
        audioo.onended = function() {
            document.getElementById(''+auid+'').innerHTML = '<i class="fa fa-play"></i> Play';
            el.setAttribute('data-status', 'Play');
        };
    });

    if(el.getAttribute('data-status') == 'stop' || el.getAttribute('data-status') == 'Play') {
        let audio = document.getElementById("audio" + id).play();
        el.setAttribute('data-status', 'Pause');
        $(this).html('<i class="fa fa-pause"></i> Pause');
    } else if(el.getAttribute('data-status') == 'Pause') {
        let audio = document.getElementById("audio" + id).pause();
        var el = document.getElementById(id);
        el.setAttribute('data-status', 'Play');
        $(this).html('<i class="fa fa-play"></i> Play');
    }
});

$(document).on('click', '.del-offer', function () {
    $(".alert-msg").html('');
    axios.delete('/offers/' + $(this).val())
    .then(res => {
        $("#offer-table").parent().load(location.href + " #offer-table");
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
    })
    .catch(err => {
        console.log(err);
    });

});

$(document).on('click', '.accept-offer', function () {
    $(".alert-msg").html('');
    var data = new FormData();
    data.append('_method', 'PUT');
    data.append('status', true);
    axios.post('/offers/' + $(this).val(), data)
    .then(res => {
        $("#offer-table").parent().load(location.href + " #offer-table");
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on('click', '.make-offer', function () {
    $('#customer_offer').submit();
    // axios.get("/make-offer/" + $(this).val())
    // .then(res => {
    //     $('#modal-hade').html(res.data.title);
    //     $('#modal-body').html(res.data.body);
    //     $('.modal-form').attr('id', res.data.formid);
    //     $('#modal-sm').modal("show");
    // })
    // .catch(err => {
    //     console.log(err);
    // });
});
// Working...
$(document).on('submit', '#customer_offer', function (e) {
    e.preventDefault();
    $(".alert-msg").html('');
    var data = new FormData(e.target);
    axios.post("/make-offer", data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
        
        // setTimeout(function () {
        //     window.location.reload();
        // }, 1500);
    })
    .catch(err => {
        if (typeof err.response.data.msg != 'undefined') {
            Swal.fire(err.response.data.msg, "", "error");
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        } else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
    });
});

$(document).on('click', '#paypalPay', function () {
    $("#paypalP").html('<i class="fa fa-spinner text-white"></i>');
    axios.get("/handle-payment")
    .then(res => {
        console.log(res.data);
        window.location = res.data;
        // $("#paypalPay").html('<span class="d-md-block fs-5"> Proceed to payment </span>');
    })
    .catch(err => {
        $("#paypalP").html('<button type="button" class="btn btn-warning" id="paypalPay"><span class="d-md-block"> Proceed to payment </span></button>');
        //$("#paypalPay").html('<span class="d-md-block fs-5"> Proceed to payment </span>');
        console.log(err);
    });
});


$(document).on('click', '.terms-condition', function () {
    axios.get("/terms-condition")
    .then(res => {
        $('#modal-title-lg').html(res.data.title);
        $('#modal-body-lg').html(res.data.body);
        $('.modal-form-lg').attr('id', res.data.formid);
        $('#modal-lg').modal("show");
    })
    .catch(err => {
        console.log(err);
    });

});

$(document).on('click', '.btn-closes', function () {
    $('.reset-password').modal("hide");
    $('.popup-registerd').modal("hide");
    $('.new_login_version').modal("hide");
    $('.alert').hide();
});

$(document).on('click', '.show-popup-login', function () {
    $('.reset-password').modal("hide");
    $('.popup-registerd').modal("hide");
    $('.alert').hide();

    $('#email').val('');
    $('#password').val('');
    $('#remember_me').prop('checked', false);

    $('.popup-login').modal("show");

    // axios.get("/login-form/login")
    // .then(res => {
    //     $('#modal-hade').html(res.data.title);
    //     $('#modal-body').html(res.data.body);
    //     $('.modal-form').attr('id', res.data.formid);
    //     $('#modal-sm').modal("show");
    // })
    // .catch(err => {
    //     console.log(err);
    // });
});

$(document).on('click', '.show-register-popup', function () {
    
    $('#name').val('');
    $('#reg-email').val('');
    $('.password-reg').val('');
    $('#password_confirmation').val('');
    $('.alert').hide();

    $('.popup-login').modal("hide");
    $('.reset-password').modal("hide");
    $('.popup-registerd').modal("show");
    
    // axios.get("/login-form/register")
    // .then(res => {
    //     $('#modal-hade').html(res.data.title);
    //     $('#modal-body').html(res.data.body);
    //     $('.modal-form').attr('id', res.data.formid);
    //     $('#modal-sm').modal("show");
    // })
    // .catch(err => {
    //     console.log(err);
    // });
});

$(document).on('click', '.reset', function () {
    $('#forget-email').val('');
    $('.alert').hide();
    
    $('.popup-login').modal("hide");
    $('.popup-registerd').modal("hide");
    $('.reset-password').modal("show");
    
    // axios.get("/login-form/reset")
    // .then(res => {
    // })
    // .catch(err => {
    //     console.log(err);
    // });
});

//login
$('#login').on("submit", function (e) {
    e.preventDefault();
    $(".alert-msg").html('');
    var formData = new FormData(e.target);
    // alert('Working...');
    if(messaging != '') {
        if (Notification.permission === 'granted') {
            console.log(messaging);

            messaging.requestPermission().then(function () {
                console.log(messaging);
                return messaging.getToken({ vapidKey: "BD5SJcThn6O5mtFzW98mlZ1_PTiHf_G1zIpghfo7bBg506P0UBsaNI3w4T3uAEpO43NFrnQE4KFPrBCoXTJJmaI" })
            }).then(function (token) {
                formData.append("token", token);
                formData.append("device_type", 'Browser');

                $(".submit").prop('disabled', true);
                axios.post("/front_login", formData)
                .then(res => {
                    $(".alert-msg").html(
                        '<div class="alert alert-success  alert-dismissible" role="alert"><div class="alert-message"><strong>Success!</strong> ' +
                        res.data.msg +
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                    );

                    $(".submit").prop('disabled', false);

                    if (res.data.data.role == 1) {
                        window.location = '/home';
                    } else {
                        setTimeout(function () {
                            window.location.reload();
                        }, 500);
                    }
                })
                .catch(err => {
                    $(".submit").prop('disabled', false);
                    if (typeof err.response.data.msg != 'undefined') {
                        $(".alert-msg").html(
                            '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                            err.response.data.msg +
                            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                        );
                    }
                    else if(err.response.data.data == 11) {
                    } else {
                        let errors = "<ul>";
                        $.each(err.response.data.errors, function (key, value) {
                            errors += "<li>" + value + "</li>";
                        });
                        errors += "</ul>";
                        $(".alert-msg").html(
                            '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                            errors +
                            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                        );
                    }
                });
            }).catch(function (err) {
                $(".submit").prop('disabled', false);
                console.log(`Token Error :: ${err}`);
            });

        }else{
            // console.log("not granted");
            //formData.append("token", token);
            //formData.append("device_type", 'Browser');
            axios.post("/front_login", formData)
            .then(res => {
                $(".alert-msg").html(
                    '<div class="alert alert-success  alert-dismissible" role="alert"><div class="alert-message"><strong>Success!</strong> ' +
                    res.data.msg +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
                if (res.data.data.role == 1) {
                    window.location = '/home';
                }
                    else {
                    setTimeout(function () {
                        window.location.reload();
                    }, 500);
                }
            })
            .catch(err => {
                if (typeof err.response.data.msg != 'undefined') {
                    $(".alert-msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                        err.response.data.msg +
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                    );
                }
                else if(err.response.data.data == 11) {
                } else {
                    let errors = "<ul>";
                    $.each(err.response.data.errors, function (key, value) {
                        errors += "<li>" + value + "</li>";
                    });
                    errors += "</ul>";
                    $(".alert-msg").html(
                        '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                        errors +
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                    );
                }
            });
        }
    }
    else{
        axios.post("/front_login", formData)
        .then(res => {
            $(".alert-msg").html(
                '<div class="alert alert-success  alert-dismissible" role="alert"><div class="alert-message"><strong>Success!</strong> ' +
                res.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
            if (res.data.data.role == 1) {
                window.location = '/home';
            }
                else {
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            }
        })
        .catch(err => {
            if (typeof err.response.data.msg != 'undefined') {
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                    err.response.data.msg +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            }
            else if(err.response.data.data == 11) {
            } else {
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert-msg").html(
                    '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                    errors +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            }
        });
    }
});

//register
$('#registeruser').on("submit" , function (e) {
    e.preventDefault();
    $(".alert-msg").html('');
    var formData = new FormData(e.target);
    if(messaging != '') {
        messaging.requestPermission().then(function () {
            return messaging.getToken({ vapidKey: "BD5SJcThn6O5mtFzW98mlZ1_PTiHf_G1zIpghfo7bBg506P0UBsaNI3w4T3uAEpO43NFrnQE4KFPrBCoXTJJmaI" })
        }).then(function (token) {
            formData.append("token", token);
            formData.append("device_type", 'Browser');
        }).catch(function (err) {
            console.log(`Token Error :: ${err}`);
        });
    }

    $(".submit").prop('disabled', true);
    axios.post("/registerform", formData)
    .then(res => {
        $(".alert-msg").html(
            '<div class="alert alert-success alert-dismissible fade show" role="alert"><strong>Success!</strong>' +
            res.data.msg +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
        );

        $(".submit").prop('disabled', false);
        setTimeout(function () {
            window.location.reload()
        }, 2800);
        
    })
    .catch(err => {
        $(".submit").prop('disabled', false);
        if (typeof err.response.data.msg != 'undefined') {
            $(".alert-msg").html(
                '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        } else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>  ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
    });
});

// password reset
$('#reset').on("submit", function (e) {
    e.preventDefault();
    $(".alert-msg").html('');
    $("#pass_reset_btn").html('<i class="fa fa-spinner"></i>');
    var data = new FormData(e.target);
    $(".submit").prop('disabled', true);
    axios.post("/password/email", data)
    .then(res => {
        console.log(res.data);
        $("#pass_reset_btn").html('Send Password Reset Link');
        $(".alert-msg").html(
            '<div class="alert alert-success  alert-dismissible" role="alert"><div class="alert-message"><strong>Success!</strong> ' +
            res.data.message +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
        );

        $(".submit").prop('disabled', false);

        window.setTimeout(function () {
            $('.reset-password').modal("hide");
            window.location.reload()
        }, 1500);
    })
    .catch(err => {
        $("#pass_reset_btn").html('Send Password Reset Link');
        if (typeof err.response.data.message != 'undefined') {
            if(err.response.data.errors.email[0] == 'Please wait before retrying.') {
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning! </strong> ' +
                    'Please try after sometimes' +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            } else {
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                    err.response.data.errors.email[0] +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            }
        } else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });

            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
        $(".submit").prop('disabled', false);
    });
});

// Search
$(document).on('click', '.search_filter', function () {
    $(".search_filter").removeClass('active');
    $(this).addClass('active');
    let type = $(this).attr("id");

    $("#type").val(type);
    if ($("#search").val() == '') {
        // Swal.fire({
        //     position: "top-end",
        //     icon: "warning",
        //     title: 'What do you want to search?',
        //     showConfirmButton: false,
        //     timer: 1500
        // });
    } else {
        $('#search_form').submit();
    }

});

$(document).on('change', '.filter_search', function () {
    let type = $(this).val();

    $("#type").val(type);
    if ($("#search").val() == '') {
        Swal.fire({
            position: "top-end",
            icon: "error",
            title: 'What do you want to search?',
            showConfirmButton: false,
            timer: 1500
        });
        $(this).val('');
    } else {
        $('#search_form').submit();
    }

});

$(".navbar-nav li a").each(function () {
    if (current == '/') {
        $(".nav-link").eq(0).parent("li").children("a").addClass("active");
    } else {
        // alert();
        if ($(this).attr("href").indexOf(current) !== -1) {
            $(this).parent("li").children("a").addClass("active");
        }
    }
});

$(".sidebar li a").each(function () {
    if (current == '/') {
        $(".nav-link").eq(0).parent("li").children("a").addClass("active");
    } else {
        // alert();
        if ($(this).attr("href").indexOf(current) !== -1) {
            $(this).parent("li").children("a").addClass("active");
        }
    }
});



$(document).on('click', '#btn-sbm', function (e) {
    $(this).parents('form').submit();
});

$(document).on('change', '.custom-file-input', function () {
    //replace the "Choose a file" label
    $(this).next('.custom-file-label').html($(this).val());
});

$.Fastselect.defaults = {

    elementClass: 'fstElementCustom',
    singleModeClass: 'fstSingleMode',
    noneSelectedClass: 'fstNoneSelected',
    multipleModeClass: 'fstMultipleMode',
    queryInputClass: 'fstQueryInput',
    queryInputExpandedClass: 'fstQueryInputExpanded',
    fakeInputClass: 'fstFakeInput',
    controlsClass: 'fstControls',
    toggleButtonClass: 'fstToggleBtn',
    activeClass: 'fstActive',
    itemSelectedClass: 'fstSelected',
    choiceItemClass: 'fstChoiceItemCustom',
    choiceRemoveClass: 'fstChoiceRemove',
    userOptionClass: 'fstUserOption',

    resultsContClass: 'fstResults',
    resultsOpenedClass: 'fstResultsOpened',
    resultsFlippedClass: 'fstResultsFilpped',
    groupClass: 'fstGroup',
    itemClass: 'fstResultItemCustom',
    groupTitleClass: 'fstGroupTitle',
    loadingClass: 'fstLoading',
    noResultsClass: 'fstNoResults',
    focusedItemClass: 'fstFocused',

    matcher: null,

    url: null,
    loadOnce: false,
    apiParam: 'query',
    initialValue: null,
    clearQueryOnSelect: true,
    minQueryLength: 1,
    focusFirstItem: false,
    flipOnBottom: true,
    typeTimeout: 150,
    userOptionAllowed: false,
    valueDelimiter: ',',
    maxItems: null,

    parseData: null,
    onItemSelect: null,
    onItemCreate: null,
    onMaxItemsReached: null,

    // placeholder: 'Choose option',
    searchPlaceholder: 'Search options',
    noResultsText: 'No results',
    userOptionPrefix: 'Add '

};

// MAP SECTION
if (ul[1] == 'checkout' || ul[1] == 'offer-checkout') {

    function map(lat, lng) {
        let latlng = new google.maps.LatLng(lat, lng);
        let image = 'http://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png';

        //  zoomControl: true,
        //  zoomControlOptions: google.maps.ZoomControlStyle.LARGE,

        var mapOptions = {
            center: new google.maps.LatLng(lat, lng),
            zoom: 13,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControl: true,
            panControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.LARGE,
                position: google.maps.ControlPosition.TOP_left
            }
        },
            map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions),
            marker = new google.maps.Marker({
                position: latlng,
                map: map,
                icon: image
            });

        var input = document.getElementById('address');
        var autocomplete = new google.maps.places.Autocomplete(input, {
            types: ["geocode"]
        });

        autocomplete.bindTo('bounds', map);
        var infowindow = new google.maps.InfoWindow();

        google.maps.event.addListener(autocomplete, 'place_changed', function (event) {
            infowindow.close();
            var place = autocomplete.getPlace();
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            moveMarker(place.name, place.geometry.location);
            $('#latitude').val(place.geometry.location.lat());
            $('#longitude').val(place.geometry.location.lng());
            // $('#latitude').val();
            // $('#longitude').val();
        });
        google.maps.event.addListener(map, 'click', function (event) {
            $('#latitude').val(event.latLng.lat());
            $('#longitude').val(event.latLng.lng());
            infowindow.close();
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                "latLng": event.latLng
            }, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var lat = results[0].geometry.location.lat(),
                        lng = results[0].geometry.location.lng(),
                        placeName = results[0].address_components[0].long_name,
                        latlng = new google.maps.LatLng(lat, lng);

                    moveMarker(placeName, latlng);
                    if(results[0].address_components[0].types == "plus_code"){
                        var address = '';
                        $.each(results[0].address_components, function(key,value){
                            if(key == 0){
                                address+='unnamed road';
                            }
                            else{
                                address+=value.long_name;
                            }
                            if(key < (results[0].address_components.length-1)){
                                console.log(key);
                                address+=', ';
                            }
                        });
                        $("#address").val(address);
                    }
                    else{
                        $("#address").val(results[0].formatted_address);
                        console.log($("#address").val());
                    }
                }
            });
        });

        function moveMarker(placeName, latlng) {
            marker.setIcon(image);
            marker.setPosition(latlng);
            infowindow.setContent(placeName);
            //infowindow.open(map, marker);
        }
    }
    $(document).on('input', '#search-input', function (e) {
        var add = (document.getElementById('search-input'));
        var autocomplete = new google.maps.places.Autocomplete(add);
        
        autocomplete.setTypes(['geocode']);
        google.maps.event.addListener(autocomplete, 'place_changed', function () {
            var place = autocomplete.getPlace();
            if (!place.geometry) {
                return;
            }

            var address = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
            }
            console.log(place.address_components);

            
        });
        var geocoder = new google.maps.Geocoder();
        var address = document.getElementById("search-input").value;
        if (address.length < 3) {
            return false;
        }
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat();
                var long = results[0].geometry.location.lng();
                map(lat, long);
                $("#latitude").val(lat);
                $("#longitude").val(lat);
                $("#address").val(results[0].formatted_address);
            }

            else {
                console.log("Geocode was not successful for the following reason: " + status);
            }
        });
    });

    // CHECKOUT SECTION ----------------------------------------------
    $(document).on('click', '#add_address', function () {
        axios.get("/add_address")
        .then(res => {
            $('#modal-title-lg').html(res.data.title);
            $('#modal-body-lg').html(res.data.body);
            $('.modal-form-lg').attr('id', res.data.formid);
            $("#delivery_hours").datetimepicker({
                inline:true,
                autoclose: true,
                todayBtn: true,
                startDate: res.data.date,
            });
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
    });

    $(document).on('click', '#edit_address', function () {
        axios.get("/edit_address/" + $(this).val())
        .then(res => {
            $('#modal-title-lg').html(res.data.title);
            $('#modal-body-lg').html(res.data.body);
            $('.modal-form-lg').attr('id', res.data.formid);
            $("#delivery_hours").datetimepicker({
                inline:true,
                autoclose: true,
                todayBtn: true,
                startDate: res.data.date,
            });
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
    });

    $(document).on('click', '#change_address', function () {
        axios.get("/address")
        .then(res => {
            $('#modal-title-lg').html(res.data.title);
            $('#modal-body-lg').html(res.data.body);
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
    });

    $(document).on('submit', '#save_address', function (e) {
        e.preventDefault();
        $(".alert-msg").html('');
        var formData = new FormData(e.target);
        axios.post("/address", formData)
        .then(res => {
            $("#modal-lg").modal('hide');
            $(".address_container").parent().load(location.href + " .address_container");
            $(".refresh-contaner").parent().load(location.href + " .refresh-contaner");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            window.setTimeout(function () {
                window.location.reload()
            }, 1500);
        })
        .catch(err => {
            if (typeof err.response.data.msg != 'undefined') {
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                    err.response.data.msg +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            } else {
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert-msg").html(
                    '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                    errors +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            }
        });
    });

    $(document).on('click', '#delete_address', function() {
        $(".alert-msg").html('');
        var id = $(this).val();
        axios.delete('/address/' + id)
			.then(res => {
                $('#modal-lg').modal("hide");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });
                // $("#modal-body-lg").parent().load(location.href + " #modal-body-lg");
				// $('.alert-msg').html(
				// 	'<div class="alert alert-success  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Success!</strong> ' +
				// 		res.data.msg +
				// 		'</div>'
				// );
				// $('.alert-dismissible')
				// 	.fadeTo(2000, 500)
				// 	.slideUp(500, function () {
				// 		$('.alert-dismissible').alert('close');
				// 	});
				//$('.modal-body-lg').load(location.href + ' .modal-body-lg');
			})
			.catch(err => {
				if (typeof err.response.data.msg != 'undefined') {
                    $(".alert-msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><div class="alert-message"><strong>Warning!</strong> ' +
                        err.response.data.msg +
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                    );
                }
                else {
                    let errors = "<ul>";
                    $.each(err.response.data.errors, function (key, value) {
                        errors += "<li>" + value + "</li>";
                    });
                    errors += "</ul>";
                    $(".alert-msg").html(
                        '<div class="alert alert-warning alert-dismissible fade show" role="alert"><strong>Warning!</strong>' +
                        errors +
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                    );
                }
			});
    });

    $(document).on('click', '.default_address', function () {
        axios.get("/set_default/" + $(this).val())
        .then(res => {
            $('#modal-lg').modal("hide");
            // $(".address_container").parent().load(location.href + " .address_container");
            // $(".refresh-contaner").parent().load(location.href + " .refresh-contaner");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            // Reload this page on chenge default address
            setTimeout(function () {
                window.location.reload();
            }, 1500);
        })
        .catch(err => {
            console.log(err);
        });
    });
}

// CALORIES SECTION ---------------------------------------------------------
$(document).on('change', '#calories_select', function (e) {

    $('#level').val('');
    $(".alert-msg").html('');

    axios.get("calories/" + $(this).val())
        .then(res => {
            $('#level').val(res.data.level);
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html(res.data.second);
            // $('#calories_select').find('option').remove();
            // $('#calories_select').append('<option value="0">Select Calories</option>');
            // $.each(res.data.calories, function (key, value) {
            //     $('#calories_select').append('<option value="' + value.id + '">' + value.name + '</option>');
            // });
        })
        .catch(err => {
            $('#level').val(err.response.data.level);
            $('#btn-addon-first').html(err.response.data.first);
            $('#btn-addon-second').html(err.response.data.second);
        });
});

$(document).on('click', '#calorie_create', function (e) {

    $(".alert-msg").html('');

    axios.post("calories", {
        level: $('#level').val()
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('#level').val('');
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html('');
            $('#calories_select').find('option').remove();
            $('#calories_select').append('<option value="0">Select Calories</option>');
            $.each(res.data.calories, function (key, value) {
                $('#calories_select').append('<option value="' + value.id + '">' + value.level + '</option>');
            });
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});

$(document).on('click', '#calorie_save', function (e) {

    $(".alert-msg").html('');

    let data = new FormData();
    data.append("_method", "PUT");
    data.append("level", $('#level').val());

    axios.post("calories/" + $('#calories_select').val(), data)

        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('#level').val('');
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html('');
            $('#calories_select').find('option').remove();
            $('#calories_select').append('<option value="0">Select Calories</option>');
            $.each(res.data.calories, function (key, value) {
                $('#calories_select').append('<option value="' + value.id + '">' + value.level + '</option>');
            });
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});

$(document).on("click", "#calorie_del", function (e) {
    $(".alert-msg").html('');
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("calories/" + $('#calories_select').val())

                .then(res => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });

                    $('#level').val('');
                    $('#btn-addon-first').html(res.data.first);
                    $('#btn-addon-second').html('');
                    $('#calories_select').find('option').remove();
                    $('#calories_select').append('<option value="0">Select Calories</option>');
                    $.each(res.data.calories, function (key, value) {
                        $('#calories_select').append('<option value="' + value.id + '">' + value.level + '</option>');
                    });
                })
                .catch(err => {
                    let errors = "<ul>";
                    $.each(err.response.data.errors, function (key, value) {
                        errors += "<li>" + value + "</li>";
                    });
                    errors += "</ul>";
                    $(".alert-msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                        errors +
                        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                    );
                });
        }
    })
});

// INGRADIENT SECTION -------------------------------------------------------
$(document).on('change', '#ingradients_select', function (e) {

    $('#ingradient_name').val('');
    $(".alert-msg").html('');

    axios.get("ingradients/" + $(this).val())
        .then(res => {
            $('#ingradient_name').val(res.data.name);
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html(res.data.second);
        })
        .catch(err => {
            $('#ingradient_name').val(err.response.data.level);
            $('#btn-addon-first').html(err.response.data.first);
            $('#btn-addon-second').html(err.response.data.second);
        });
});

$(document).on('click', '#ingradient_create', function (e) {

    $(".alert-msg").html('');

    axios.post("ingradients", {
        name: $('#ingradient_name').val()
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('#ingradient_name').val('');
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html('');
            $('#ingradients_select').find('option').remove();
            $('#ingradients_select').append('<option value="0">Select Ingradients</option>');
            $.each(res.data.ingradients, function (key, value) {
                $('#ingradients_select').append('<option value="' + value.id + '">' + value.name + '</option>');
            });
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});

// TAXT & DELIVERY COST SECTION ---------------------------------------------
$(document).on('click', '#txt_dc_save', function () {

    $(".alert-msg").html('');

    let data = new FormData();
    data.append("_method", "PUT");
    // data.append("tax", $('#tax').val());
    data.append("delivery_cost", $('#delivery_cost').val());

    axios.post("charges/" + 1, data)

        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});

// FOODS SECTION ------------------------------------------------------------
$(document).on('click', '#food_create', function (e) {
    axios.get("foods/create")
    .then(res => {
        $('.modal-form').attr('id', res.data.formid);
        $('#modal-title').html(res.data.title);
        $('#modal-body').html(res.data.body);
        $('#btn-sbm').html(res.data.btntxt);
        // $('#ingradient').fastselect();
        // $('#allergies').tagsinput();
        $('#ingradient').tagsinput();
        $('#served_with').tagsinput();
                    
        $('#modal-lg').modal("show");
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on('submit', '#insertFood', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);

    axios.post("foods", data)
    .then(res => {
        console.log(res.data);
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        $(".card-body").load(location.href + " .card-body");
        $("#btn-sbm").html('Submit');
        $('#modal-lg').modal("hide");
        window.location.reload();
        
        // $('#food-table').DataTable({
        //     "destroy": true,
        // });
    })
    .catch(err => {
        $("#btn-sbm").html('Submit');
        if (typeof err.response.data.msg != 'undefined') {
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
        else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
    });
});

$(document).on('click', '.edit-food', function (e) {
    axios.get('foods/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#ingradient').tagsinput();
            $('#served_with').tagsinput();

            // $('#allergies').tagsinput();
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on('submit', '#saveFood', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
    if($("#is_clickable").prop('checked') == true){
        data.set('is_clickable', '1');
    } else {
        data.set('is_clickable', '0');
    }
    
    data.append("_method", "PUT");

    axios.post("foods/" + $('#id').val(), data)

    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        $(".card-body").load(location.href + " .card-body");
        $("#btn-sbm").html('Submit');
        $('#modal-lg').modal("hide");
        window.location.reload();
    })
    .catch(err => {
        $("#btn-sbm").html('Submit');
        if (typeof err.response.data.msg != 'undefined') {
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
        else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
    });
});

$(document).on("click", '.del-food', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("foods/" + $(this).val())
            .then(res => {
                $(".card-body").load(location.href + " .card-body");
                if (res.data.stat == false) {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.data.msg,
                        showConfirmButton: false,
                    });
                }
                else {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                setTimeout(function () {
                    // $('#modal-sm').modal("hide");
                    window.location.reload();
                }, 500);
                // window.location.reload();

            })
            .catch(err => {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: err.response.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

            });
        }
    })
});

$(document).on("click", '.del-regeneration', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("regeneration/" + $(this).val())
            .then(res => {
                $(".card-body").load(location.href + " .card-body");
                if (res.data.stat == false) {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.data.msg,
                        showConfirmButton: false,
                    });
                }
                else {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                setTimeout(function () {
                    // $('#modal-sm').modal("hide");
                    window.location.reload();
                }, 500);
                // window.location.reload();

            })
            .catch(err => {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: err.response.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
    })
});

$(document).on("click", '.del-allergie', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("allergies/" + $(this).val())
            .then(res => {
                $(".card-body").load(location.href + " .card-body");
                if (res.data.stat == false) {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.data.msg,
                        showConfirmButton: false,
                    });
                }
                else {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            })
            .catch(err => {
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: err.response.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });
            });
        }
    })
});

$(document).on("click", '.default-comboimage', function () {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("field", $('#field').val());
    data.append("value", $('#id').val());
    data.append("type", 'default');
    axios.post("comboimages/" + $(this).val(), data)
        .then(res => {
            let data = '';
            $('.carousel-inner').html('');
            $.each(res.data.images, function (key, value) {
                if (value.is_default == '1') {
                    data += '<div class="carousel-item active">';
                    data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                    data += '</div>';
                } else {
                    data += '<div class="carousel-item">';
                    data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                    data += '<div class="carousel-caption d-none d-md-block">';
                    data += '<button type="button" class="btn btn-success default-image ml-2" value="' + value.id + '">Default</button>';
                    data += '<button type="button" class="btn btn-danger del-image" value="' + value.id + '">Delete</button>';
                    data += '</div>';
                    data += '</div>';
                }
            });

            data += '<button class="carousel-control-prev" type="button" data-bs-target="#itemCarousel" data-bs-slide="prev">';
            data += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
            data += '<span class="visually-hidden">Previous</span>';
            data += '</button>';
            data += '<button class="carousel-control-next" type="button" data-bs-target="#itemCarousel" data-bs-slide="next">';
            data += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
            data += '<span class="visually-hidden">Next</span>';
            data += '</button>';

            $('.carousel-inner').html(data);
            $(".card-body").load(location.href + " .card-body");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            
            window.location.reload();
        })
        .catch(err => {
            console.log(err);

        });

});

$(document).on("click", '.del-comboimage', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            let data = new FormData();
            data.append("_method", "PUT");
            data.append("field", $('#field').val());
            data.append("value", $('#id').val());
            data.append("type", 'delete');
            axios.post("comboimages/" + $(this).val(), data)
            .then(res => {
                let data = '';
                $('.carousel-inner').html('');
                $.each(res.data.images, function (key, value) {
                    if (value.is_default == '1') {
                        data += '<div class="carousel-item active">';
                        data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                        data += '</div>';
                    } else {
                        data += '<div class="carousel-item">';
                        data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                        data += '<div class="carousel-caption d-none d-md-block">';
                        data += '<button type="button" class="btn btn-success default-image ml-2" value="' + value.id + '">Default</button>';
                        data += '<button type="button" class="btn btn-danger del-image" value="' + value.id + '">Delete</button>';
                        data += '</div>';
                        data += '</div>';
                    }
                });

                data += '<button class="carousel-control-prev" type="button" data-bs-target="#itemCarousel" data-bs-slide="prev">';
                data += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
                data += '<span class="visually-hidden">Previous</span>';
                data += '</button>';
                data += '<button class="carousel-control-next" type="button" data-bs-target="#itemCarousel" data-bs-slide="next">';
                data += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
                data += '<span class="visually-hidden">Next</span>';
                data += '</button>';

                $('.carousel-inner').html(data);
                $(".card-body").load(location.href + " .card-body");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
            });
        }
    })
});

$(document).on("click", '.default-image', function () {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("field", $('#field').val());
    data.append("value", $('#id').val());
    data.append("type", 'default');
    axios.post("images/" + $(this).val(), data)
        .then(res => {
            let data = '';
            $('.carousel-inner').html('');
            $.each(res.data.images, function (key, value) {
                if (value.is_default == '1') {
                    data += '<div class="carousel-item active">';
                    data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                    data += '</div>';
                } else {
                    data += '<div class="carousel-item">';
                    data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                    data += '<div class="carousel-caption d-none d-md-block">';
                    data += '<button type="button" class="btn btn-success default-image ml-2" value="' + value.id + '">Default</button>';
                    data += '<button type="button" class="btn btn-danger del-image" value="' + value.id + '">Delete</button>';
                    data += '</div>';
                    data += '</div>';
                }
            });

            data += '<button class="carousel-control-prev" type="button" data-bs-target="#itemCarousel" data-bs-slide="prev">';
            data += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
            data += '<span class="visually-hidden">Previous</span>';
            data += '</button>';
            data += '<button class="carousel-control-next" type="button" data-bs-target="#itemCarousel" data-bs-slide="next">';
            data += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
            data += '<span class="visually-hidden">Next</span>';
            data += '</button>';

            $('.carousel-inner').html(data);
            $(".card-body").load(location.href + " .card-body");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
        })
        .catch(err => {
            console.log(err);

        });

});

$(document).on("click", '.del-image', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            let data = new FormData();
            data.append("_method", "PUT");
            data.append("field", $('#field').val());
            data.append("value", $('#id').val());
            data.append("type", 'delete');
            axios.post("images/" + $(this).val(), data)
                .then(res => {
                    let data = '';
                    $('.carousel-inner').html('');
                    $.each(res.data.images, function (key, value) {
                        if (value.is_default == '1') {
                            data += '<div class="carousel-item active">';
                            data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                            data += '</div>';
                        } else {
                            data += '<div class="carousel-item">';
                            data += '<img src="' + $('#url').val() + '/storage/images/' + value.image + '" class="d-block w-100">';
                            data += '<div class="carousel-caption d-none d-md-block">';
                            data += '<button type="button" class="btn btn-success default-image ml-2" value="' + value.id + '">Default</button>';
                            data += '<button type="button" class="btn btn-danger del-image" value="' + value.id + '">Delete</button>';
                            data += '</div>';
                            data += '</div>';
                        }
                    });

                    data += '<button class="carousel-control-prev" type="button" data-bs-target="#itemCarousel" data-bs-slide="prev">';
                    data += '<span class="carousel-control-prev-icon" aria-hidden="true"></span>';
                    data += '<span class="visually-hidden">Previous</span>';
                    data += '</button>';
                    data += '<button class="carousel-control-next" type="button" data-bs-target="#itemCarousel" data-bs-slide="next">';
                    data += '<span class="carousel-control-next-icon" aria-hidden="true"></span>';
                    data += '<span class="visually-hidden">Next</span>';
                    data += '</button>';

                    $('.carousel-inner').html(data);
                    $(".card-body").load(location.href + " .card-body");
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .catch(err => {
                    console.log(err);

                });
        }
    })

});

// COMBOS SECTION CHANGE TYPE
$(document).on('click', '#type', function (e) {
    if($(this).val() == "normal"){
        $("#sixth_item").hide();
        $("#seventh_item").hide();
        $("#eighth_item").hide();
        $("#nineth_item").hide();
        $("#tenth_item").hide();
    } else if($(this).val() == "global"){
        $("#sixth_item").show();
        $("#seventh_item").show();
        $("#eighth_item").show();
        $("#nineth_item").show();
        $("#tenth_item").show();
    }
});

$(document).on('click', '#combo_create', function (e) {
    axios.get("combos/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);

            $("#sixth_item").hide();
            $("#seventh_item").hide();
            $("#eighth_item").hide();
            $("#nineth_item").hide();
            $("#tenth_item").hide();
            
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on('submit', '#insertCombo', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);

    axios.post("combos", data)

        .then(res => {
            console.log(res.data);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $(".card-body").load(location.href + " .card-body");
            $("#btn-sbm").html('Submit');
            $('#modal-lg').modal("hide");
            window.location.reload();
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            if (typeof err.response.data.msg != 'undefined') {
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    err.response.data.msg +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            }
            else {
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            }
        });
});

$(document).on('click', '.edit-combo', function (e) {
    axios.get('combos/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on('submit', '#saveCombo', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
    
    if($("#is_combo").prop('checked') == true){
        data.set("is_combo", "1");
    } else {
        data.set("is_combo", "0");
    }

    data.append("_method", "PUT");

    axios.post("combos/" + $('#id').val(), data)

        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $(".card-body").load(location.href + " .card-body");
            $("#btn-sbm").html('Submit');
            $('#modal-lg').modal("hide");
            window.location.reload();
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});

$(document).on("click", '.del-combo', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("combos/" + $(this).val())
            .then(res => {
                $(".card-body").load(location.href + " .card-body");
                window.location.reload();
                if (res.data.stat == false) {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.data.msg,
                        showConfirmButton: false,
                    });
                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
            })
            .catch(err => {
                console.log(err);

            });
        }
    })
});

// COUNTRY SECTION BY SAGNIK -------------------------------------------------
$(document).on('click','#country_create',function(e) {
    axios.get("country/create")
    .then(res => {
        $('.modal-form').attr('id', res.data.formid);
        $('#modal-title').html(res.data.title);
        $('#modal-body').html(res.data.body);
        $('#btn-sbm').html(res.data.btntxt);
        $('#modal-lg').modal("show");
    }).catch(err => {
        $(".alert-msg").html(
            '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
            err.response.data.msg +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
        );
        // console.log(err);
    });
});

$(document).on('click', '#delayed_status', function(e){
    if(this.checked){
        $("#delay_status").val("1");
    }
    else{
        $("#delay_status").val("0");
    }
    console.log($("#delay_status").val());
})

$(document).on('submit', '#insertCountry', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
   

    axios.post("/country/store", data)

        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            //$(".card-body").load(location.href + " .card-body");
            $("#btn-sbm").html('Submit');
            $('#modal-lg').modal("hide");
            window.location.reload();
            // $('#country-table').DataTable({
            //     "destroy": true,
            // });
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});
// Edit form of country
$(document).on('click', '.edit-country', function (e) {
    axios.get('country/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});
// update country
$(document).on('submit', '#saveCountry', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
    data.append("_method", "PUT");

    axios.post("country/" + $('#id').val(), data)

        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $("#btn-sbm").html('Submit');
            $('#modal-lg').modal("hide");
            window.location.reload();
            // var datatable =  $('#country-table').DataTable();
            // datatable.clear().draw();
            // $(".card-body").load(location.href + " .card-body");
            // $('#country-table').DataTable({
            //     "destroy": true,
            // });
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});
// delete country
$(document).on("click", '.del-country', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("country/" + $(this).val())
                .then(res => {
                    if (res.data.stat == false) {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: res.data.msg,
                            showConfirmButton: false,
                        });
                    }
                    else {
                        //console.log('true');
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        //$(".card-body").load(location.href + " .card-body");
                        $("#btn-sbm").html('Submit');
                        $('#modal-lg').modal("hide");
                        window.location.reload();
                        // $('#country-table').DataTable({
                        //     "destroy": true,
                        // });
                        
                    }

                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});


// DESTINATION SECTION -------------------------------------------------------

$(document).on('click', '#destination_create', function (e) {
    axios.get("destinations/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on('submit', '#insertDestination', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
    // console.log(data);
    axios.post("destinations", data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        // $(".card-body").load(location.href + " .card-body");
        $("#btn-sbm").html('Submit');
        $('#modal-lg').modal("hide");
        // window.location.reload();
        // $('#destination-table').DataTable({
        //     "destroy": true,
        // });
    })
    .catch(err => {
        $("#btn-sbm").html('Submit');
        let errors = "<ul>";
        $.each(err.response.data.errors, function (key, value) {
            errors += "<li>" + value + "</li>";
        });
        errors += "</ul>";
        $(".alert-msg").html(
            '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
            errors +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
        );
    });
});

$(document).on('click', '.edit-destination', function (e) {
    axios.get('destinations/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#modal-lg').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on('submit', '#saveDestination', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
    data.append("_method", "PUT");

    axios.post("destinations/" + $('#id').val(), data)

        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $("#btn-sbm").html('Submit');
            $('#modal-lg').modal("hide");
            window.location.reload();
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        });
});

$(document).on("click", '.del-destination', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("destinations/" + $(this).val())
                .then(res => {
                    if (res.data.stat == false) {
                        console.log('flase');
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: res.data.msg,
                            showConfirmButton: false,
                        });
                    }
                    else {
                        console.log('true');
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        window.location.reload();
                    }

                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});

// ORIGIN SECTION ------------------------------------------------------------

$(document).on('change', '#origin_select', function (e) {

    $('#origin_name').val('');
    $('#origin_code').val('');
    $(".alert-msg").html('');

    axios.get("origins/" + $(this).val())
    .then(res => {
        $('#origin_name').val(res.data.name);
        $('#origin_code').val(res.data.code);
        $('#btn-addon-first').html(res.data.first);
        $('#btn-addon-second').html(res.data.second);
    })
    .catch(err => {
        $('#origin_name').val(err.response.data.level);
        $('#btn-addon-first').html(err.response.data.first);
        $('#btn-addon-second').html(err.response.data.second);
    });
});

$(document).on('click', '#origin_create', function (e) {

    $(".alert-msg").html('');

    axios.post("origins", {
        name: $('#origin_name').val(),
        code: $('#origin_code').val()
    })
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        $('#origin_name').val('');
        $('#origin_code').val('');
        $('#btn-addon-first').html(res.data.first);
        $('#btn-addon-second').html('');
        $('#origin_select').find('option').remove();
        $('#origin_select').append('<option value="0">Select Origins</option>');
        $.each(res.data.origins, function (key, value) {
            $('#origin_select').append('<option value="' + value.id + '">' + value.name + '</option>');
        });
    })
    .catch(err => {
        let errors = "<ul>";
        $.each(err.response.data.errors, function (key, value) {
            errors += "<li>" + value + "</li>";
        });
        errors += "</ul>";
        $(".alert-msg").html(
            '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
            errors +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
        );
    });
});

$(document).on('click', '#origin_save', function (e) {

    $(".alert-msg").html('');

    let data = new FormData();
    data.append("_method", "PUT");
    data.append("name", $('#origin_name').val());
    data.append("code", $('#origin_code').val());

    axios.post("origins/" + $('#origin_select').val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        $('#origin_name').val('');
        $('#origin_code').val('');
        $('#btn-addon-first').html(res.data.first);
        $('#btn-addon-second').html('');
        $('#origin_select').find('option').remove();
        $('#origin_select').append('<option value="0">Select Origins</option>');
        $.each(res.data.origins, function (key, value) {
            $('#origin_select').append('<option value="' + value.id + '">' + value.name + '</option>');
        });
    })
    .catch(err => {
        let errors = "<ul>";
        $.each(err.response.data.errors, function (key, value) {
            errors += "<li>" + value + "</li>";
        });
        errors += "</ul>";
        $(".alert-msg").html(
            '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
            errors +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
        );
    });
});

$(document).on("click", "#origin_del", function (e) {
    $(".alert-msg").html('');
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("origins/" + $('#origin_select').val())
            .then(res => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                $('#origin_name').val('');
                $('#origin_code').val('');
                $('#btn-addon-first').html(res.data.first);
                $('#btn-addon-second').html('');
                $('#origin_select').find('option').remove();
                $('#origin_select').append('<option value="0">Select Origins</option>');
                $.each(res.data.origins, function (key, value) {
                    $('#origin_select').append('<option value="' + value.id + '">' + value.name + '</option>');
                });
            })
            .catch(err => {
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert-msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
                );
            });
        }
    })
});

// TRIPS SECTION ---------------------------------------------------------------

$(document).on('click', '#trip_create', function (e) {
    axios.get("trips/create")
    .then(res => {
        $('.modal-form').attr('id', res.data.formid);
        $('#modal-title').html(res.data.title);
        $('#modal-body').html(res.data.body);
        $('#btn-sbm').html(res.data.btntxt);
        $('#modal-lg').modal("show");
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on('submit', '#insertTrip', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);

    axios.post("trips", data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        //$(".card-body").load(location.href + " .card-body");
        $("#btn-sbm").html('Submit');
        $('#modal-lg').modal("hide");
        //window.location.reload();
        // $('#trip-table').DataTable({
        //     "destroy": true,
        // });
    })
    .catch(err => {
        $("#btn-sbm").html('Submit');
        if (typeof err.response.data.msg != 'undefined') {
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        } else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
    });
});

$(document).on('click', '.edit-trip', function (e) {
    axios.get('trips/' + $(this).val() + '/edit')
    .then(res => {
        $('.modal-form').attr('id', res.data.formid);
        $('#modal-title').html(res.data.title);
        $('#modal-body').html(res.data.body);
        $('#btn-sbm').html(res.data.btntxt);
        $('#modal-lg').modal("show");
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on('submit', '#saveTrip', function (e) {

    $(".alert-msg").html('');
    e.preventDefault();
    $("#btn-sbm").html('<i class="fa fa-spinner"></i>');

    let data = new FormData(e.target);
    data.append("_method", "PUT");

    axios.post("trips/" + $('#id').val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        //$(".card-body").load(location.href + " .card-body");
        $("#btn-sbm").html('Submit');
        $('#modal-lg').modal("hide");
        window.location.reload();
        // $('#trip-table').DataTable({
        //     "destroy": true,
        // });
    })
    .catch(err => {
        $("#btn-sbm").html('Submit');
        if (typeof err.response.data.msg != 'undefined') {
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                err.response.data.msg +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        } else {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'
            );
        }
    });
});

$(document).on("click", '.del-trip', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("trips/" + $(this).val())
            .then(res => {
                $(".card-body").load(location.href + " .card-body");
                // $('#trip-table').DataTable({
                //     "destroy": true,
                // });
                if (res.data.stat == false) {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: res.data.msg,
                        showConfirmButton: false,
                    });
                }
                else {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    window.location.reload();
                }
                window.location.reload();

            })
            .catch(err => {
                console.log(err);

            });
        }
    })
});

$(document).on('change', '#trip_status', function (e) {
    $(".alert-msg").html('');

    if ($(this).val() == 'LAST MINUTE') {
        $(".trip-qty").html('<label for="quantity">Quantity</label><input type="text" name="quantity" class="form-control" id="quantity" placeholder="Enter Quantity"/>');
    } else {

        $(".trip-qty").html('');
    }


});

// USER SECTION ----------------------------------------------------
// $(document).on('click', '.user-view', function (e) {
//     axios.get('users/' + $(this).val())
//         .then(res => {
//             $('#modal-lg').modal("show");
//         })
//         .catch(err => {
//             console.log(err);
//         });
// });

$(document).on('click', '.user-edit', function () {

    let data = new FormData();
    data.append("_method", "PUT");
    data.append("status", $(this).html());

    axios.post("users/" + $(this).val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
        window.location.reload();

        // $(".card-body").load(location.href + " .card-body");

        // $('#user-table').DataTable({
        //     "destroy": true,
        // });
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on("click", '.user-del', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("users/" + $(this).val())
            .then(res => {
                // $(".card-body").load(location.href + " .card-body");
                // $('#user-table').DataTable({
                //     "destroy": true,
                // });
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });
                window.location.reload();

            })
            .catch(err => {
                console.log(err);
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: err.response.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

            });
        }
    })
});

// ORDER SECTION ----------------------------------------------------

$(document).on("click", '.order-details', function () {
    axios.get("/orders/" + $(this).val() + '/edit')
    .then(res => {
        $('#modal-title').html(res.data.title);
        $('#modal-body').html(res.data.body);
        $('#btn-sbm').hide();
        $('#modal-lg').modal("show");
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on("click", '.accept-order', function () {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("cancel", 0);
    axios.post("orders/" + $(this).val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        // $(".card-body").load(location.href + " .card-body");
        $('#modal-lg').modal("hide");

        setTimeout(function () {
            window.location.reload();
        }, 1500);
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on("click", '.cancel-order', function () {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("cancel", 1);
    axios.post("orders/" + $(this).val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        //$(".card-body").parent().load(location.href + " .card-body");
        $('#modal-lg').modal("hide");
        window.location.reload();
        // $('.order-table').DataTable({
        //     "destroy": true,
        // });
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on("click", '.refund-order', function () {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("cancel", 0);
    axios.post("orders/" + $(this).val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        $(".card-body").parent().load(location.href + " .card-body");
        $('#modal-lg').modal("hide");
        window.location.reload();
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on("click", '.status-review', function () {
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("status", $(this).html());
    axios.post("reviews/" + $(this).val(), data)
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });

        $(".card-body").load(location.href + " .card-body");
    })
    .catch(err => {
        console.log(err);
    });
});

$(document).on("click", '.del-review', function () {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it !'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("reviews/" + $(this).val())
            .then(res => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                //$(".card-body").load(location.href + " .card-body");
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
            });
        }
    })
});


// Datatables
// $("#example1").DataTable({
//     "responsive": true, "lengthChange": false, "autoWidth": false,
//     "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"]
//   });
// $('#exampl').DataTable();
if (current == '/' || ul[1] == 'page') {
    $(document).ready(function(){
        $('[data-toggle="tooltipp"]').tooltip();  
    });
    window.setTimeout(function () {
        $("#success-alert").fadeTo(500, 0).slideUp(500, function () {
            $(this).remove();
        });
    }, 4000);
} else if (current == '/foods') {
    $('#food-table').DataTable({
        "destroy": true,
    });
} else if (current == '/origins') {
    $('#origin-table').DataTable({
        "destroy": true,
    });
} else if (current == '/country') {
    $('#country-table').DataTable({
        "destroy": true,
    });    
} else if (current == '/destinations') {
    $('#destination-table').DataTable({
        "destroy": true,
    });
} else if (current == '/trips') {
    $('#trip-table').DataTable({
        "destroy": true,
    });
} else if (current == '/combos') {
    $('#combo-table').DataTable({
        "destroy": true,
    });
} else if (current == '/offers') {
    $('#offer-table').DataTable({
        "destroy": true,
    });
} else if (current == '/orders') {
    var dt = $('.order-table').DataTable({
        "destroy": true,
        "ordering": false,
    });
}
else if (current == '/reviews') {
    $('#offer-table').DataTable({
        "destroy": true,
    });
}
else if (current == '/users') {
    $('#user-table').DataTable({
        "destroy": true,
    });
}
else if (current == '/my-orders') {
    window.setTimeout(function () {
        $("#success-alert").fadeTo(500, 0).slideUp(500, function () {
            $(this).remove();
        });
    }, 4000);
}
else if (ul[1] == 'godestination'){
    $(document).ready(function(){
        $('[data-toggle="tooltipp"]').tooltip();  
    });
} else {
    $('#common-table').DataTable({
        "destroy": true,
    });
}

// $(document).on('click.bs.dropdown.data-api', '.dropdown.keep-inside-clicks-open', function (e) {
//     alert("cvcbxc");
//     e.stopPropagation();
// });

$("#ddds").click(function(){
    $(".navbar-collapse").removeClass("show");
    $(this).css("display", "none");
})

$(".navbar-toggler").click(function(){
    $("#ddds").css("display", "block");
})

$('.short-banner').owlCarousel({
    navigation : navstat,
    nav: true,
    dots: true,
    center: false,
    loop: true,
    margin: 15,
    autoplay: 900,
    autoPlaySpeed: 900,
    autoPlayTimeout: 900,
    autoplayHoverPause: false,
    mouseDrag: navstat,
    items:3,
    singleItem:true,
    stagePadding: 0,
    responsive : {
        // breakpoint from 0 up
        1280 : {items:3},
        0 : {items:3},                
        991 : {items:2},
        767 : {items:2},
        576 : {items:2},
        480 : {items:1},
        360 : {items:1},
        320 : {items:1}
    }

});

// $( "#custom-handle" ).on( "change", function(e) {
//     console.log($('#custom-handle').text());
//     $('#make-offer-price').val($('#customRange1').val());
//     $('#bidPrice').text($('#customRange1').val());
// });

$( function() {
    var handle = $( "#custom-handle" );
    $( "#slider" ).slider({
        range: "min",
        min: 8,
        max: $("#max_value").val(),
        value: 1,
        create: function() {
            handle.text( $( this ).slider( "value" ) );
        },
        slide: function( event, ui ) {
            handle.text( ui.value );
            $( "#make-offer-price" ).val( ui.value );
        }
    });
});

$(document).ready(function(){
    if (current == '/foods') {
        // alert('Subhodeep');
        $(".js-example-basic-multiple").select2({
            multiple: true,
            tags: true,
        });
    }
});

$(document).ready(function(){
    $(".navbar").click(function(){
        if($('.navbar-toggler').hasClass('collapsed')){
            $('.navbar-toggler').removeClass('bi-x');
            $('.navbar-toggler').addClass('bi-list');

        }else{
            $('.navbar-toggler').removeClass('bi-list');
            $('.navbar-toggler').addClass('bi-x');
        }
    })

    // Inicial owl crowsel
    $("#foodSlider").owlCarousel({
        navigation : true,
        nav: false,
        dots: true,
        center: true,
        loop: true,
        margin: 0,
        autoplay: 900,
        autoPlaySpeed: 900,
        autoPlayTimeout: 900,
        autoplayHoverPause: false,
        items:1,
    
        // autoWidth:true
        
    });

    function myFunction() {
        var x = document.getElementById("passwords");
        if (x.type === "password") {
          x.type = "text";
        } else {
          x.type = "password";
        }
      }

});


